$my-primary: #3e5a81;
$my-primary: #6cc816;
$my-primary: #325798;
$my-primary: #004899;

$my-secondary: #94b7e1;
$my-secondary: #0d619b;
$my-secondary: #e7db3a;
$my-secondary: #0098a1;

$my-tertiary: #cccfd2;
$my-light: #e0dfde;
$my-dark: #151f2c;

$theme-colors: (
    "primary": $my-primary,
    "secondary": $my-secondary,
    "tertiary": $my-tertiary,
    "light": $my-light,
    "dark": $my-dark,
);

@import "node_modules/bootstrap/scss/bootstrap";

@import "_customSettings";
@import "_customLayout";
