.bg {
    padding: 3rem 0;
}

.bg-primary {
    background: $my-primary;
    color: $white;
}

.bg-gradient-primary * {
    color: $white;
}

.bg-primary a,
.bg-gradient-primary a {
    color: $white;
}

.btn-primary {
    color: $white;
}

.bg-secondary {
    background: $my-secondary;
    color: $white;
}

.bg-tertiary {
    background: $my-tertiary;
    color: $dark;
}

.bg-light {
    background: $my-light;
    color: $dark;
}

.bg-img {
    background: url(../img/intro-bg.jpg) no-repeat center center fixed;
    background-size: cover;
    background-position: 0;
    padding-top: 5rem;
}

.center {
    text-align: center;
}

.display-3 {
    font-size: 4rem;
}

/*------------------------------------*\
    font-size
\*------------------------------------*/
h1 {
    font-size: 24px;
  }
  @media screen and (min-width: 320px) {
    h1 {
      font-size: calc(24px + 6 * ((100vw - 320px) / 680));
    }
  }
  @media screen and (min-width: 1000px) {
    h1 {
      font-size: 32px;
    }
  }

  .jumbotron h2 {
    font-size: 24px;
  }
  @media screen and (min-width: 320px) {
    .jumbotron h2 {
      font-size: calc(24px + 6 * ((100vw - 320px) / 680));
    }
  }
  @media screen and (min-width: 1000px) {
    .jumbotron h2 {
      font-size: 48px;
    }
  }

  h2 {
    font-size: 24px;
    color: $my-primary;
  }
  @media screen and (min-width: 320px) {
    h2 {
      font-size: calc(24px + 6 * ((100vw - 320px) / 680));
    }
  }
  @media screen and (min-width: 1000px) {
    h2 {
      font-size: 32px;
    }
  }

/*------------------------------------*\
    Icons Feather 
\*------------------------------------*/

.check-list,
.thumbs-up-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.check-list .feather {
    width: 32px;
    height: 32px;
    padding-right: 1rem;
    stroke-width: 5;
    stroke: $my-primary;
}

.thumbs-up-list .feather {
    width: 32px;
    height: 32px;
    padding-right: 1rem;
    stroke-width: 3;
    stroke: $my-primary;
}

.feather {
    width: 24px;
    height: 24px;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    display: inline-block;
}

.big-icons .feather {
    width: 60px;
    height: 60px;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    //border: 5px solid white;
    //border-radius: 50%;
    //padding: 20px;
}

svg + a {
    padding-left: 0.25rem;
}
