body {
    background: $white;
    padding-top: 100px;
}

h1,
h2,
h3,
h4 {
    font-weight: 400;
}

h5,
h6 {
    font-weight: 400;
}

ul {
    margin-left: -1.25rem;
}

a {
    cursor: pointer;
}

.list-group {
    margin-left: 0;
}

.navbar-light .navbar-brand {
    margin-top: 0.75rem;
}

.keyvisual-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1rem 1.5rem;
    background-blend-mode: multiply;
}

.keyvisual-text .content {
    height: fit-content;
}

/*------------------------------------*\
    Header
\*------------------------------------*/
.dropdown-menu-inner {
    padding: 1rem;
    min-width: 320px;
}

.dropdown-menu-inner p {
    word-wrap: none;
}

/*------------------------------------*\
    Cards
\*------------------------------------*/
.card {
    display: flex;
    flex-direction: column;
    flex: 0 1 24%;
    align-self: stretch;
    height: 100%;
}

.card-body {
    align-self: stretch;
}

.btn {
    margin-right: 1rem;
}

/*------------------------------------*\
    Footer
\*------------------------------------*/

.footer {
    margin: 1.5rem auto;
}

/*------------------------------------*\
    Modal Layer Impressum
\*------------------------------------*/
.modal-body * {
    color: $body-color;
}

.footer span {
    word-wrap: none;
    white-space: nowrap;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 50vw;
        margin: 1.75rem auto;
    }
}
